<template>
  <v-container fluid>

    <v-row>        
      <v-col
        cols="12"
      >
        <v-card
          dark
          outlined
        >
          <v-card-title class="secondary py-1 text-subtitle-1">
            
            <v-icon left dense> mdi-home </v-icon>
            Portal Groups
            <v-spacer/>

          </v-card-title>
          <v-card-text class="pa-0">
            <v-data-table
              :headers="headers"
              :items="portalUsers"
              single-select
              class="elevation-5"
            >
            <template v-slot:item.UserGroupId="{ item }">
                {{ item.UserGroupId ? item.UserGroupId : 'No data available' }}
            </template>
            <template v-slot:item.EventId="{ item }">
                {{ item.EventId ? haddleCheckEvent(item.EventId).join(', ') : 'No data available' }}
            </template>
            <template v-slot:item.selectedEvents="{ item }">
                {{ item.selectedEvents ? haddleCheckEvents(item.selectedEvents).join(', ') : 'No data available' }}
            </template>
            <template v-slot:top>
              <v-toolbar
                flat
              >
              <v-row class="mt-2 mb-2">
                <v-col cols="12"></v-col>
              </v-row>
                <v-dialog
                  v-model="dialog"
                  max-width="800px"
                  dark
                >
                
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      x-small
                      dark
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon left x-small>
                        mdi-plus
                      </v-icon>
                      Add Portal Group
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="text-h6">
                      {{ formTitle }}
                    </v-card-title>
                    <v-divider></v-divider>

                    <v-card-text>
                      <v-container>
                       <v-form 
                          ref="form"
                          v-model="valid"
                          lazy-validation
                        >

                          <v-text-field
                          v-model="editedItem.UserName"
                          label="Group Name"
                          :rules="[v => !!v || 'Group Name is required']"
                          ></v-text-field>

                          <!-- <v-autocomplete
                              :items="eventsSum"
                              v-model="editedItem.EventId"
                              item-text="text"
                              return-object
                              filled
                              dense
                              outlined
                              hide-details
                              label="Default Event"
                              class="mb-5"
                          >
                              <template v-slot:no-data>
                              <v-list-item>
                                  <v-list-item-title>
                                  <v-icon small color="red lighten-1">
                                      mdi-information
                                  </v-icon>
                                  No event found
                                  </v-list-item-title>
                              </v-list-item>
                              </template>
                          </v-autocomplete> -->
                          
                          <span>Permissions</span>
                          
                          <v-divider></v-divider>
                          <!-- event -->
                          <v-row v-for="(item, index) in Events" :key="index">
                            <v-col cols="6" class="mt-3">
                              <label v-if="item.EventId.text && editedIndex === -1" >Event Name :- {{item.EventId.text ? item.EventId.text: ''}}</label>
                              <label v-if="item.EventId && editedIndex !== -1" >Event Name :- {{item.EventId ? haddleCheckEvent(item.EventId).join(', ') : ''}}</label>
                              <v-autocomplete
                                  v-if="!item.EventId.text && !item.EventId"
                                  :items="eventsSum"
                                  v-model="item.EventId"
                                  item-text="text"
                                  return-object
                                  filled
                                  dense
                                  outlined
                                  hide-details
                                  label="Events"
                                  @change="(event) => haddleChangeEvent(event, index)"
                              >
                                  <template v-slot:no-data>
                                  <v-list-item>
                                      <v-list-item-title>
                                      <v-icon small color="red lighten-1">
                                          mdi-information
                                      </v-icon>
                                      No event found
                                      </v-list-item-title>
                                  </v-list-item>
                                  </template>
                              </v-autocomplete>
                            </v-col>

                            <v-col cols="3" class="mt-3" style="padding-right: 0px !important">
                              <label v-if="item.Permission.name  && editedIndex === -1" >Permission :- {{item.Permission.name ? item.Permission.name: ''}}</label>
                              <label v-if="item.Permission && editedIndex !== -1" >Permission :- {{item.Permission ? haddleCheckPermission(item.Permission).join(', '): ''}}</label>
                              <v-autocomplete
                                  v-if="!item.Permission.name && !item.Permission"
                                  :items="PermissionList"
                                  v-model="item.Permission"
                                  item-text="text"
                                  return-object
                                  filled
                                  dense
                                  outlined
                                  hide-details
                                  label="Permission"
                              >
                                  <template v-slot:no-data>
                                  <v-list-item>
                                      <v-list-item-title>
                                      <v-icon small color="red lighten-1">
                                          mdi-information
                                      </v-icon>
                                      No event found
                                      </v-list-item-title>
                                  </v-list-item>
                                  </template>
                              </v-autocomplete>
                            </v-col>

                            <v-col cols="3" class="mt-5" style="padding-left: 24px !important">
                              <v-btn color="red lighten-2" x-small v-show="index || (!index && Events.length > 1)" @click="removeRowEvents(index)">Remove</v-btn> 
                                &nbsp;
                              <v-btn color="lighten-2" x-small v-show="index == Events.length-1" @click="addRowEvents()">Add</v-btn>
                            </v-col>
                            
                            <!-- Stallholders -->
                            <v-row v-for="(itemSH, indexsh) in item.Stallholders" :key="indexsh">

                              <v-col cols="5" class="mt-3" offset-md="1">
                                <label v-if="itemSH.StallholderId.StallholderName && editedIndex === -1" >Stallholder Name :- {{itemSH.StallholderId.StallholderName ? itemSH.StallholderId.StallholderName: ''}}</label>
                                <label v-if="itemSH.StallholderId && editedIndex !== -1" >Stallholder Name :- {{itemSH.StallholderId ? haddleCheckEventStallHolder(item.EventId, itemSH.StallholderId).join(', ') : ''}}</label>
                                <v-autocomplete
                                    v-if="!itemSH.StallholderId.StallholderName && !itemSH.StallholderId"
                                    v-model="itemSH.StallholderId"
                                    :items="allStallHolders"
                                    item-text="StallholderName"
                                    return-object
                                    filled
                                    dense
                                    outlined
                                    hide-details
                                    label="StallHolders"
                                    @change="(stallHolder) => haddleChangeStallholder(stallHolder, indexsh)"
                                >
                                    <template v-slot:no-data>
                                    <v-list-item>
                                        <v-list-item-title>
                                        <v-icon small color="red lighten-1">
                                            mdi-information
                                        </v-icon>
                                        No event found
                                        </v-list-item-title>
                                    </v-list-item>
                                    </template>
                                </v-autocomplete>
                              </v-col>

                              <v-col cols="3" class="mt-3" style="padding-right: 6px !important">
                                <label v-if="itemSH.Permission.name && editedIndex === -1" >Permission :- {{itemSH.Permission.name ? itemSH.Permission.name: ''}}</label>
                                <label v-if="itemSH.Permission && editedIndex !== -1" >Permission :- {{itemSH.Permission ? haddleCheckPermission(itemSH.Permission).join(', ') : ''}}</label>
                              
                                <v-autocomplete
                                    v-if="!itemSH.Permission.name && !itemSH.Permission"
                                    :items="PermissionList"
                                    v-model="itemSH.Permission"
                                    item-text="text"
                                    return-object
                                    filled
                                    dense
                                    outlined
                                    hide-details
                                    label="Permission"
                                >
                                    <template v-slot:no-data>
                                    <v-list-item>
                                        <v-list-item-title>
                                        <v-icon small color="red lighten-1">
                                            mdi-information
                                        </v-icon>
                                        No event found
                                        </v-list-item-title>
                                    </v-list-item>
                                    </template>
                                </v-autocomplete>
                              </v-col>

                              <v-col cols="3" class="mt-5" style="padding-left: 18px !important">
                                <v-btn color="red lighten-2" x-small v-show="indexsh || (!indexsh && item.Stallholders.length > 1)" @click="removeRowStallholders(index, indexsh)">Remove</v-btn> 
                                  &nbsp;
                                <v-btn color="lighten-2" x-small v-show="indexsh == item.Stallholders.length-1" @click="addRowStallholders(index, indexsh)">Add</v-btn>
                              </v-col>

                              <!-- Stalls -->
                              <v-row v-for="(itemS, indexs) in itemSH.Stalls" :key="indexs">
                                
                                <v-col cols="4" class="mt-3" offset-md="2">
                                  <label v-if="itemS.StallId.StallName && editedIndex === -1">Stall Name :- {{itemS.StallId.StallName ? itemS.StallId.StallName: ''}}</label>
                                  <label v-if="itemS.StallId && editedIndex !== -1">Stall Name :- {{itemS.StallId ? haddleCheckEventStallHolderStall(item.EventId, itemSH.StallholderId, itemS.StallId).join(', ') : ''}}</label>
                                  <v-autocomplete
                                      v-if="!itemS.StallId.StallName && !itemS.StallId"
                                      v-model="itemS.StallId"
                                      :items="allStalls"
                                      item-text="StallName"
                                      return-object
                                      filled
                                      dense
                                      outlined
                                      hide-details
                                      label="Stalls"
                                  >
                                      <template v-slot:no-data>
                                      <v-list-item>
                                          <v-list-item-title>
                                          <v-icon small color="red lighten-1">
                                              mdi-information
                                          </v-icon>
                                          No event found
                                          </v-list-item-title>
                                      </v-list-item>
                                      </template>
                                  </v-autocomplete>
                                </v-col>

                                <v-col cols="3" class="mt-3">                                  
                                  <label v-if="itemS.Permission.name && editedIndex === -1" >Permission :- {{itemS.Permission.name ? itemS.Permission.name: ''}}</label>
                                  <label v-if="itemS.Permission && editedIndex !== -1" >Permission :- {{itemS.Permission ? haddleCheckPermission(itemS.Permission).join(', ') : ''}}</label>

                                  <v-autocomplete
                                      v-if="!itemS.Permission.name && !itemS.Permission"
                                      :items="PermissionList"
                                      v-model="itemS.Permission"
                                      item-text="text"
                                      return-object
                                      filled
                                      dense
                                      outlined
                                      hide-details
                                      label="Permission"
                                  >
                                      <template v-slot:no-data>
                                      <v-list-item>
                                          <v-list-item-title>
                                          <v-icon small color="red lighten-1">
                                              mdi-information
                                          </v-icon>
                                          No event found
                                          </v-list-item-title>
                                      </v-list-item>
                                      </template>
                                  </v-autocomplete>
                                </v-col>

                                <v-col cols="3" class="mt-5">
                                  <v-btn color="red lighten-2" x-small v-show="indexs || (!indexs && itemSH.Stalls.length > 1)" @click="removeRowStalls(index, indexsh, indexs)">Remove</v-btn> 
                                    &nbsp;
                                  <v-btn color="lighten-2" x-small v-show="indexs == itemSH.Stalls.length-1" @click="addRowStalls(index, indexsh, indexs)">Add</v-btn>
                                </v-col>

                              </v-row>
                            
                            </v-row>
                            <!-- <v-divider class="mt-5"></v-divider> -->
                            
                          </v-row>

                       </v-form>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="close"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="save"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="text-h5">Are you sure you want to delete Portal Group?</v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                      <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                small
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
            <template v-slot:no-data>
              <v-btn
                color="primary"
              >
                Updating with new data....
              </v-btn>
            </template>
            </v-data-table>

          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import 'izitoast/dist/css/iziToast.min.css'; // loading css 
import iziToast from 'izitoast/dist/js/iziToast.min.js';  // you have access to iziToast now
import {
  mapState,
  mapActions,
  mapMutations
} from 'vuex'

export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,

    headers: [
      { text: "User Group ID", value: "UserGroupId"},
      { text: "Events", value: "selectedEvents"},
      { text: 'Actions', value: 'actions', sortable: false }
    ],

    portalUsers: [],

    editedIndex: -1,
    editedItem: {
      Events: [],
      UserName: ''
    },
    defaultItem: {
      Events: [],
      UserName: ''
    },
    
    PermissionList: [
      { name: 'Write', text: 'Write'},
      { name: 'Read', text: 'Read'},
      { name: 'None', text: 'None'},
      { name: 'Stock', text: 'Stock'}
    ],
    
    Events: [
      {
        EventId: '',
        Permission: '',
        Stallholders: [
          { 
            StallholderId: '', 
            Permission: '', 
            Stalls: [
              {
                StallId: '',
                Permission: ''
              }
            ]
          }
        ]
      }
    ],

    valid: true
  }),

  computed: {
    ...mapState('filter', ['globalEventFilter', 'allEvents', 'allStallHolders', 'allStalls']),
    formTitle () {
      return this.editedIndex === -1 ? 'New portal Group' : 'Edit portal Group'
    },
    eventsSum: {
      get: function () {
        return this.allEvents.map((obj) => {
          return {text: obj.EventName, value: obj.EventId, obj}
        });
      },
      set: function (newValue) {
        console.log(newValue)
      }
    }
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },

  created() {
    this.getAllProducts()
  },
  methods: {
    ...mapMutations('filter', ['setGlobalEventFilter', 'setAllEvents', 'setFilterStallHolders', 'setAllStallHolders', 'setAllStalls']),
    ...mapActions('admin', ['getPortalUsers', 'createPortalUsers',]),
    getAllProducts() {
      this.getPortalUsers().then(response => {
        console.log("response")
        console.log(response)
        this.portalUsers = response.Groups.map(obj=>{
          obj.selectedEvents = obj.Events && obj.Events !== undefined ? obj.Events : '';
          return obj;
        });
      })
    },
    haddleCheckEvents: function(events) {
      var result = this.eventsSum.filter(function(o1){
        return events.some(function(o2){
            return o1.value === o2.EventId;
        });
      }).map(function(o){
          return o.text;
      });
      return result;
    },

    haddleCheckEvent: function (EventId)  {
      var result = this.eventsSum.filter(element => {
        return element.value === EventId || element.value === EventId.value;
      }).map(function(o){
          return o.text;
      });
      return result;
    },

    haddleCheckPermission: function (Permission) {
      var result = this.PermissionList.filter(element => {
        return element.name === Permission || element.name === Permission.name;
      }).map(function(o){
          return o.text;
      });
      return result;
    },

    haddleCheckEventStallHolder: function (EventId, StallholderId) {
      var result = this.eventsSum.filter(element => {
        return element.value === EventId;
      }).map(function(o){
          return o;
      });

      var stallHolderResult = result[0].obj.Stallholders.filter(element => {
        return element.StallholderId === StallholderId;
      }).map(function(o){
          return o.StallholderName;
      });

      return stallHolderResult;
    },

    haddleCheckEventStallHolderStall: function (EventId, StallholderId, StallId) {
      var result = this.eventsSum.filter(element => {
        return element.value === EventId;
      }).map(function(o){
          return o;
      });

      var stallHolderresult = result[0].obj.Stallholders.filter(element => {
        return element.StallholderId === StallholderId;
      }).map(function(o){
          return o;
      });

      var stallResult = stallHolderresult[0].Stalls.filter(element => {
        return element.StallId === StallId;
      }).map(function(o){
          return o.StallName;
      });

      return stallResult;
    },

    editItem (item) {
      console.log("item")
      console.log(item)
      this.editedIndex = this.portalUsers.indexOf(item)

      this.editedItem.UserGroupId = item.UserGroupId;
      this.editedItem.UserName = item.UserGroupId
      this.Events = item.selectedEvents;

      for (var j = 0; j < item.selectedEvents.length; j++) {
        console.log(item.selectedEvents[j].EventId)
        
         let result = this.eventsSum.map(obj=>{
          if (obj.value === item.selectedEvents[j].EventId) {
            console.log("obj")
            console.log(obj.obj.Stallholders)
            this.setAllStallHolders(obj.obj.Stallholders)
          }
          return obj;
        });

        console.log(result)

      }

      this.dialog = true
    },

    haddleChangeEvent: function(object, index) {
      console.log(object.obj.Stallholders)
      console.log(index)
      this.setAllStallHolders(object.obj.Stallholders)
    },

    haddleChangeStallholder: function(object, index) {
      console.log(object.Stalls)
      console.log(index)
      this.setAllStalls(object.Stalls)
      // this.setAllStallHolders(object.obj.Stallholders)
    },

    addRowEvents() {
      let x = this.Events.length -1;
      if (this.Events[x].EventId && this.Events[x].Permission) {
        this.Events.push(
          {
            EventId: '',
            Permission: '',
            Stallholders: [
              { 
                StallholderId: '', 
                Permission: '', 
                Stalls: [
                  {
                    StallId: '',
                    Permission: ''
                  }
                ]
              }
            ]
          }
        );
      }

      for (var j = 0; j < this.Events.length; j++) {
        if (this.Events[j].EventId !== undefined) {
          this.eventsSum = this.eventsSum.map(obj=>{
            if (obj.value === this.Events[j].EventId.value) {
              obj.disabled = true;
            }
            return obj;
          });
        }
      }
    },
    removeRowEvents(index){
      for (var j = 0; j < this.Events.length; j++) {
        if (this.Events[j].EventId !== undefined) {
          this.eventsSum = this.eventsSum.map(obj=>{
            if (obj.value === this.Events[index].EventId.value) {
              obj.disabled = false;
            }
            return obj;
          });
        }
      }

      this.Events.splice(index, 1);
    },

    addRowStallholders(index, indexsh) {
      if (this.Events[index].Stallholders[indexsh].StallholderId && this.Events[index].Stallholders[indexsh].Permission) {
        this.Events[index].Stallholders.push(
          { 
            StallholderId: '', 
            Permission: '', 
            Stalls: [
              {
                StallId: '',
                Permission: ''
              }
            ]
          }
        );
      }

      for (var j = 0; j < this.Events[index].Stallholders.length; j++) {
        if (this.Events[index].Stallholders[j].StallholderId !== undefined) {
          this.allStallHolders = this.allStallHolders.map(obj=>{
            if (obj.StallholderId === this.Events[index].Stallholders[j].StallholderId.StallholderId) {
              obj.disabled = true;
            }
            return obj;
          });
        }
      }
    },
    removeRowStallholders(index, indexsh) {
      for (var j = 0; j < this.Events[index].Stallholders.length; j++) {
        if (this.Events[index].Stallholders[j].StallholderId !== undefined) {
          this.allStallHolders = this.allStallHolders.map(obj=>{
            if (obj.StallholderId === this.Events[indexsh].Stallholders[j].StallholderId.StallholderId) {
              obj.disabled = false;
            }
            return obj;
          });
        }
      }

      this.Events[index].Stallholders.splice(indexsh, 1);
    },

    addRowStalls(index, indexsh, indexs) {
      if (this.Events[index].Stallholders[indexsh].Stalls[indexs].StallId && this.Events[index].Stallholders[indexsh].Stalls[indexs].Permission) {
        this.Events[index].Stallholders[indexsh].Stalls.push(
          { 
            StallId: '', 
            Permission: ''
          }
        );
      }

      for (var j = 0; j < this.Events[index].Stallholders[indexsh].Stalls.length; j++) {
        if (this.Events[index].Stallholders[indexsh].Stalls[j].StallId !== undefined) {
          this.allStalls = this.allStalls.map(obj=>{
            if (obj.StallId === this.Events[indexsh].Stallholders[indexs].Stalls[j].StallId.StallId) {
              obj.disabled = true;
            }
            return obj;
          });
        }
      }
    },
    removeRowStalls(index, indexsh, indexs) {
      for (var j = 0; j < this.Events[index].Stallholders[indexsh].Stalls.length; j++) {
        if (this.Events[index].Stallholders[indexsh].Stalls[j].StallId !== undefined) {
          this.allStalls = this.allStalls.map(obj=>{
            if (obj.StallId === this.Events[index].Stallholders[indexsh].Stalls[j].StallId.StallId) {
              obj.disabled = false;
            }
            return obj;
          });
        }
      }

      this.Events[index].Stallholders[indexsh].Stalls.splice(indexs, 1);
    },

    deleteItem (item) {
      this.editedIndex = this.portalUsers.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.portalUsers.splice(this.editedIndex, 1)
      
      try {
        if (this.editedItem.UserGroupId) {
            let event_item = [
                {
                    Type: "usergroup",
                    Action: "delete",
                    Properties: this.editedItem
                }
            ]
            
            let submitEvent = {
                event_items: event_item
            }
            this.createPortalUsers(submitEvent).then(response => {
                console.log("response")
                console.log(response)

                iziToast.success({
                    title: 'Portal Groups',
                    message: 'Successfully delete record!',
                    position: 'topRight'
                });

                this.getAllProducts();

            }).catch(err => {
                console.log("err")
                console.log(err)

                iziToast.warning({
                    title: 'Portal Groups',
                    message: 'Fail to delete record!',
                    position: 'topRight'
                });
            })
        }
      } catch (e) {
        console.log(e)
      }

      this.closeDelete()
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
      this.Events = [];
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save () {
      console.log("submit--1")
      console.log(this.editedItem)

      for (var j = 0; j < this.Events.length; j++) {
        this.Events[j].EventId = this.Events[j].EventId.value;
        this.Events[j].Permission = this.Events[j].Permission.name;
        
        for (var k = 0; k < this.Events[j].Stallholders.length; k++) {
          this.Events[j].Stallholders[k].StallholderId = this.Events[j].Stallholders[k].StallholderId.StallholderId;
          this.Events[j].Stallholders[k].Permission = this.Events[j].Stallholders[k].Permission.name;
          
          for (var l = 0; l < this.Events[j].Stallholders[k].Stalls.length; l++) {
            this.Events[j].Stallholders[k].Stalls[l].StallId = this.Events[j].Stallholders[k].Stalls[l].StallId.StallId;
            this.Events[j].Stallholders[k].Stalls[l].Permission = this.Events[j].Stallholders[k].Stalls[l].Permission.name;
          }
        }
      }

      this.editedItem.Events = this.Events;
      console.log("this.editedItem")
      console.log(this.editedItem)
      if (this.$refs.form.validate()) {

        try {
          if (this.$refs.form.validate()) {

              let event_item = [
                  {
                      Type: "usergroup",
                      Action: this.editedIndex === -1 ? 'Create' : 'Edit',
                      Properties: this.editedItem
                  }
              ]
              
              let submitEvent = {
                  event_items: event_item
              }
              console.log("submitEvent")
              console.log(submitEvent)
              this.createPortalUsers(submitEvent).then(response => {
                  console.log("response")
                  console.log(response)

                  iziToast.success({
                      title: 'Portal Groups',
                      message: 'Successfully inserted record!',
                      position: 'topRight'
                  });

                  this.getAllProducts();

              }).catch(err => {
                  console.log("err")
                  console.log(err)

                  iziToast.warning({
                      title: 'Portal Groups',
                      message: 'Fail to inserted record!',
                      position: 'topRight'
                  });
              })
          }
        } catch (e) {
          console.log(e)
        }
        this.close()
      }
    }
  }
}
</script>
<style scoped>
h1{
  color: #a09c94;
  font-family: sans-serif;
}
</style>